<template lang="pug">
  div.row
    div.col-12
      button.btn.btn-outline-primary.mx-1(type="button", @click="get_bandwidth('')") Get Bandwidth
      button.btn.btn-outline-primary.mx-1(type="button", @click="get_storage_load('')") Get Storage&amp;Load Average
      button.btn.btn-outline-danger.mx-1(type="button", @click="add_monitor") Add MonitorV2
    div.col-12.mt-3
      h5 Manager
      table.table
        thead
          tr
            th.text-center(style="width:50px;") ID
            th(style="width:200px;") Host
            th.text-center(style="width:120px;") Storage(Avail%)
            th.text-center(style="width:145px;") CPU
            th.text-center(style="width:145px;") Load(1m,5m,15m)
            th.text-center(style="width:150px;") Bandwidth(GiB)
            th.text-center(style="width:135px;") Local IP
            th.text-center(style="width:135px;") Global IP
            th
        tbody
          tr(v-for="m in manager", :key="m.id")
            td.text-center {{ m.id }}
            td {{ m.host }}
            td.text-center {{ m.storage }}
            td.text-center {{ m.cpu }}
            td.text-center {{ m.load }}
            td.text-center {{ m.bandwidth }}
            td.text-center {{ m.local }}
            td.text-center {{ m.global }}
            td
    div.col-12.mt-3
      h5 Registry
      table.table
        thead
          tr
            th.text-center(style="width:50px;") ID
            th(style="width:200px;") Host
            th.text-center(style="width:120px;") Storage(Avail%)
            th.text-center(style="width:145px;") CPU
            th.text-center(style="width:145px;") Load(1m,5m,15m)
            th.text-center(style="width:150px;") Bandwidth(GiB)
            th.text-center(style="width:135px;") Local IP
            th.text-center(style="width:135px;") Global IP
            th
        tbody
          tr
            td.text-center {{ registry.id }}
            td {{ registry.host }}
            td.text-center {{ registry.storage }}
            td.text-center {{ registry.cpu }}
            td.text-center {{ registry.load }}
            td.text-center {{ registry.bandwidth }}
            td.text-center {{ registry.local }}
            td.text-center {{ registry.global }}
            td
    div.col-12.mt-3
      h5 Balancer
      table.table
        thead
          tr
            th.text-center(style="width:50px;") ID
            th(style="width:200px;") Host
            th.text-center(style="width:120px;") Storage(Avail%)
            th.text-center(style="width:145px;") CPU
            th.text-center(style="width:145px;") Load(1m,5m,15m)
            th.text-center(style="width:150px;") Bandwidth(GiB)
            th.text-center(style="width:135px;") Local IP
            th.text-center(style="width:135px;") Global IP
            th
        tbody
          tr
            td.text-center {{ balancer.id }}
            td {{ balancer.host }}
            td.text-center {{ balancer.storage }}
            td.text-center {{ balancer.cpu }}
            td.text-center {{ balancer.load }}
            td.text-center {{ balancer.bandwidth }}
            td.text-center {{ balancer.local }}
            td.text-center {{ balancer.global }}
            td
    div.col-12.mt-3
      h5 App
      table.table
        thead
          tr
            th.text-center(style="width:50px;") ID
            th(style="width:200px;") Host
            th.text-center(style="width:120px;") Storage(Avail%)
            th.text-center(style="width:145px;") CPU
            th.text-center(style="width:145px;") Load(1m,5m,15m)
            th.text-center(style="width:150px;") Bandwidth(GiB)
            th.text-center(style="width:135px;") Local IP
            th.text-center(style="width:135px;") Global IP
            th
        tbody
          tr(v-for="m in app", :key="m.id")
            td.text-center {{ m.id }}
            td {{ m.host }}
            td.text-center {{ m.storage }}
            td.text-center {{ m.cpu }}
            td.text-center {{ m.load }}
            td.text-center {{ m.bandwidth }}
            td.text-center {{ m.local }}
            td.text-center {{ m.global }}
            td
    div.col-12.mt-3
      h5 MonitorV2
      table.table
        thead
          tr
            th.text-center(style="width:50px;") ID
            th(style="width:200px;") Host
            th.text-center(style="width:120px;") Score
            th.text-center(style="width:120px;") Storage(Avail%)
            th.text-center(style="width:145px;") CPU
            th.text-center(style="width:145px;") Load(1m,5m,15m)
            th.text-center(style="width:150px;") Bandwidth(GiB)
            th.text-center(style="width:135px;") Container IP
            th.text-center(style="width:135px;") Global IP
            th
        tbody
          tr(v-for="m in monitor", :key="m.id")
            td.text-center {{ m.id }}
            td {{ m.host }}
            td.text-center {{ m.score }}
            td.text-center {{ m.storage }}
            td.text-center {{ m.cpu }}
            td.text-center {{ m.load }}
            td.text-center {{ m.bandwidth }}
            td.text-center {{ m.ip }}
            td.text-center {{ m.global }}
            td
              button.btn.btn-sm.btn-outline-primary.mx-1(type="button", @click="show_jobs(m.id)") Job

</template>

<script>
import moment from 'moment';


export default {
  name:"Servers",
  components:{
  },
  data (){
    return {
      monitor:[],
      manager:[],
      app:[],
      balancer:{},
      registry:{}   
    }
  },
  mounted (){
    this.get();
  },
  methods: {
    loading (mes=""){
      let spinner = document.createElement("div");
      spinner.classList.add("spinner-border", "text-secondary");
      let p = document.createElement("p");
      p.innerText = mes;
      let div = document.createElement("div");
      div.appendChild(spinner);
      div.appendChild(p);
      this.$swal({ content:div, button:false });
    },
    get (){
      this.axios.get("/api/servers")
      .then((response) => {
        this.monitor = response.data.monitor;
        this.monitor.sort((a, b) => a.id - b.id);
        this.manager = response.data.manager;
        this.balancer = response.data.balancer;
        this.registry = response.data.registry;
        this.app = response.data.app;
      })
    },
    async show_jobs (id){
      let jobs = (await this.axios.get("/api/v2/monitor_jobs?id="+id)).data;
      let html = '<table class="table table-sm">';
      html += "<thead><tr><th>ID</th><th>Trigger</th><th>Next</th></tr></thead>";
      html += "<tbody>";
      for (let i=0; i<jobs.length;i++)
        html += "<tr><td>"+jobs[i].id+"</td><td>"+jobs[i].trigger+"</td><td>"+moment(new Date(jobs[i].next_run_at*1000)).format('YYYY/MM/DD HH:mm:ss')+"</td></tr>";
      html += "</tbody></table>"
      let div = document.createElement("div");
      div.innerHTML = html;
      this.$swal({ content:div });

    },
    async get_bandwidth(host=""){
      this.loading();
      let url = "/api/bandwidth";
      if (host)
        url = url + "?host="+host;

      try{
        let res = await this.axios.get(url);
        for(let i=0;i<this.manager.length;i++)
          this.manager[i].bandwidth = res.data[this.manager[i].host]? res.data[this.manager[i].host].bandwidth:this.manager[i].bandwidth;
        this.balancer.bandwidth = res.data[this.balancer.host]? res.data[this.balancer.host].bandwidth:this.balancer.bandwidth;
        this.registry.bandwidth = res.data[this.registry.host]? res.data[this.registry.host].bandwidth:this.registry.bandwidth;
        for(let i=0;i<this.app.length;i++)
          this.app[i].bandwidth = res.data[this.app[i].host]? res.data[this.app[i].host].bandwidth:this.app[i].bandwidth;
        for(let i=0;i<this.monitor.length;i++)
          this.monitor[i].bandwidth = res.data[this.monitor[i].host]? res.data[this.monitor[i].host].bandwidth:this.monitor[i].bandwidth;
        this.$swal.close();
      }
      catch(err){
        console.log(err);
      }
    },
    async get_storage_load(host=""){
      this.loading();
      let url = "/api/storage_load";
      if (host)
        url = url + "?host="+host;

      try{
        let res = await this.axios.get(url);
        for(let i=0;i<this.manager.length;i++){
          this.manager[i].storage = res.data[this.manager[i].host]? res.data[this.manager[i].host].storage:this.manager[i].storage;
          this.manager[i].load = res.data[this.manager[i].host]? res.data[this.manager[i].host].load:this.manager[i].load;
          this.manager[i].cpu = res.data[this.manager[i].host]? res.data[this.manager[i].host].cpu:this.manager[i].cpu;
        }
        this.balancer.storage = res.data[this.balancer.host]? res.data[this.balancer.host].storage:this.balancer.storage;
        this.balancer.load = res.data[this.balancer.host]? res.data[this.balancer.host].load:this.balancer.load;
        this.balancer.cpu = res.data[this.balancer.host]? res.data[this.balancer.host].cpu:this.balancer.cpu;
        this.registry.storage = res.data[this.registry.host]? res.data[this.registry.host].storage:this.registry.storage;
        this.registry.load = res.data[this.registry.host]? res.data[this.registry.host].load:this.registry.load;
        this.registry.vpu = res.data[this.registry.host]? res.data[this.registry.host].vpu:this.registry.vpu;
        for(let i=0;i<this.app.length;i++){
          this.app[i].storage = res.data[this.app[i].host]? res.data[this.app[i].host].storage:this.app[i].storage;
          this.app[i].load = res.data[this.app[i].host]? res.data[this.app[i].host].load:this.app[i].load;
          this.app[i].cpu = res.data[this.app[i].host]? res.data[this.app[i].host].cpu:this.app[i].cpu;
        }
        for(let i=0;i<this.monitor.length;i++){
          this.monitor[i].storage = res.data[this.monitor[i].host]? res.data[this.monitor[i].host].storage:this.monitor[i].storage;
          this.monitor[i].load = res.data[this.monitor[i].host]? res.data[this.monitor[i].host].load:this.monitor[i].load;
          this.monitor[i].cpu = res.data[this.monitor[i].host]? res.data[this.monitor[i].host].cpu:this.monitor[i].cpu;
        }
        this.$swal.close();
      }
      catch(err){
        console.log(err);
      }
    },
    async add_monitor(){
      let val = await this.$swal("MonitorV2サーバー( $6/1Month )を追加しますか?", { dangerMode:true, buttons:true });
      if(!val)
        return;
      let pass = await this.$swal("Enter Password", { content:"input" });
      if (pass){
        try{
          this.loading();
          let res = await this.axios.post("/api/v2/add_monitor", { pass });
          if (res.status==200)
            this.$swal("", res.data.label+"が追加されました", "success");
          else
            this.$swal("Error","","error");
            console.log(res);
        }
        catch(err){
          this.$swal("Error","","error");
          console.log(err);
        }
      }
    },

  }
}
</script>

<style lang="scss">

th,td {
  vertical-align: middle;
}

</style>